.container {
  padding: 0 2rem;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.authPanel {
  .title {
    margin-bottom: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
  }
}
